import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Row, Col, Container, Nav, CloseButton } from 'react-bootstrap';
import styled from 'styled-components';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import BannerContainer from '@components/bannerContainer';

const OrderedList = styled.ol`
  counter-reset: list;
  margin: 0;
  padding: 0;
  & > li {
    list-style: none;
    margin-bottom: 1rem;
    &:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
    }
  }
`;

const PrivacyPage = () => {
  return (
    <Layout
      headerProps={{
        disableLogin: true,
        NavigationOptions: (
          <Nav.Link onClick={() => navigate(-1)} className="ms-3">
            <CloseButton />
          </Nav.Link>
        ),
      }}
    >
      <SEO title="Política de privacidad" />

      <Container>
        <BannerContainer title="Política de privacidad" />

        <Row>
          <Col xs="12" md="10" lg="8" className="mx-auto py-4">
            <p>
              Por favor toma unos minutos para leer la siguiente política para
              que comprendas cómo tratamos tu información. A medida que vayamos
              continuamente mejorando y expandiendo nuestros servicios, esta
              política puede ir cambiando. Por favor revísala periódicamente.
              Esta Política de Privacidad aplica a los sitios web de Pizzería
              Habana en línea y móviles.
            </p>

            <p>
              <OrderedList>
                <li>
                  La información personal del cliente será tratada de forma
                  confidencial y no será revelada a terceros.
                  <br />
                  Una vez registrada la información personal del cliente, la
                  misma quedará sujeta a los términos de la Política de
                  Privacidad, y excepto en el caso de que dicha revelación sea
                  necesaria por imposición de la ley o en virtud de un acuerdo o
                  petición emitida por el Regulador Fiscal u otra autoridad
                  legal o reguladora competente, deberá ser accesible sólo para
                  nosotros, nuestros agentes, o por una empresa controlada por
                  nosotros; y será procesada o utilizada por nosotros para los
                  propósitos del servicio que brindamos (y que el cliente
                  solicita), y de una manera compatible con el cumplimiento de
                  nuestra obligación en virtud del Acuerdo. La información
                  personal del cliente se archiva en nuestro servidor de manera
                  encriptada para proteger su privacidad y brindar la mayor
                  seguridad.
                </li>
                <li>
                  Información que recopilamos y permisos.
                  <br />
                  Recopilamos la siguiente información de los clientes de manera
                  directa o indirecta:
                  <br />
                  Nombres y apellidos.
                  <br />
                  Correo electrónico.
                  <br />
                  Número de teléfono.
                  <br />
                  Métodos de pago.
                  <br />
                  Dirección particular.
                  <br />
                  Historial de compras
                  <br />
                  Esta información personal es necesaria para brindar nuestros
                  servicios y darle una experiencia personalizada a nuestro
                  cliente. Se pedirá al usuario su aceptación de nuestros
                  Términos y Condiciones y de nuestra Política de Privacidad
                  para guardar su información personal y crear su cuenta en
                  Pizzería Habana.
                </li>
                <li>
                  Uso de la información personal del cliente.
                  <br />
                  Podemos usar esta información para lo siguiente:
                  <br />
                  Para brindar al cliente información sobre productos o
                  servicios que le puedan interesar.
                  <br />
                  Para notificar al cliente acerca de cualquier cambio en
                  nuestro sitio web/aplicación o en nuestros servicios.
                  <br />
                  Para asegurarnos que el contenido de nuestro sitio
                  web/aplicación sea presentado al cliente de la manera más
                  efectiva.
                  <br />
                  Para brindar la mejor atención al cliente.
                  <br />
                  Para propósitos de seguridad y prevención de fraude.
                  <br />
                  Para análisis de marketing.
                </li>
                <li>
                  Otros permisos.
                  <br />
                  La aplicación móvil de Pizzería Habana, dependiendo de la
                  plataforma, pudiera pedir permiso a nuestros clientes para
                  utilizar recursos del teléfono (o del dispositivo) como el
                  micrófono para el manejo y la grabación de audio. Estos
                  recursos resultan necesarios para cumplir con el servicio de
                  comunicación que brindamos, por ejemplo, las llamadas
                  telefónicas para realizar pedidos.
                </li>
                <li>
                  Eliminar la información personal del cliente de nuestro
                  sistema.
                  <br />
                  El cliente puede eliminar su cuenta del sistema de Pizzería
                  Habana cuando lo desee. Para ello debe comunicar su solicitud
                  de eliminación de cuenta al equipo de atención al cliente a
                  través del correo electrónico soporte@pizzeriahabana.com o
                  llamando al teléfono +1(305)859-4472. El cliente deberá
                  utilizar el mismo correo electrónico y/o el mismo número
                  telefónico registrado en su cuenta. En un breve intervalo de
                  tiempo toda la información personal del cliente será eliminada
                  del registro de Pizzería Habana.
                </li>
              </OrderedList>
            </p>
            <p>
              <b>
                Al utilizar nuestros servicios y aceptar nuestros Términos y
                Condiciones, usted acepta esta Política de Privacidad.
              </b>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PrivacyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
